import IncreasedPercentage from "./UtilityLeague/IncreasedPercentage";

interface props {
  name: string;
  siteName: string;
  value: number;
  percentage: number;
}
export default function Card({ name, value, percentage, siteName }: props) {
  return (
    <div
      className="card"
      style={{
        borderRadius: "12px",
      }}
    >
      <div
        className="card-header bg-white text-center fw-bold p-1"
        style={{ fontSize: "20px" }}
      >
        {name}
      </div>
      <div
        className="bg-white text-center font-12"
        style={{ fontSize: "16px" }}
      >
        {siteName}
      </div>
      <div className="card-body d-flex flex-column align-items-center p-2">
        <div
          className="text-center px-4 mb-2 py-1 d-flex flex-column"
          style={{
            backgroundColor: "#4D505C",
            color: "white",
            borderRadius: "18px",
            filter: "drop-shadow(0px 0px 6px #4D505C7A)",
          }}
        >
          <span
            style={{
              fontSize: "12px",
            }}
          >
            Actual Monthly usage
          </span>
          <span
            style={{
              fontSize: "16px",
            }}
          >
            {value} kWh
          </span>
        </div>
        <IncreasedPercentage
          style={{
            fontSize: "20px",
          }}
          percentage={percentage}
        />
      </div>
    </div>
  );
}
