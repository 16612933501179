type IncreasedPercentageProps = {
  percentage?: number;
  style?: React.CSSProperties;
  className?: string;
  invertColors?: boolean;
};
const IncreasedPercentage = (p: IncreasedPercentageProps) => {
  const negativePercentageOpt = {
    color: "#94C11F",
    val: Math.abs(p.percentage!)?.toString() + "%",
    icon: "fas fa-arrow-down",
  };
  const plusPercetageOpt = {
    color: "#E97871",
    val: p.percentage?.toString() + "%",
    icon: "fas fa-arrow-up",
  };
  const noPercetageOpt = {
    color: "gray",
    val: "N/A",
    icon: "",
  };
  const zeroPercentageOpt = {
    color: "gray",
    val: "0",
    icon: "",
  };

  let opt = p.invertColors === true ? plusPercetageOpt : negativePercentageOpt;
  if (p.percentage === undefined) {
    opt = noPercetageOpt;
  } else if (p.percentage > 0) {
    opt = p.invertColors === true ? negativePercentageOpt : plusPercetageOpt;
  } else if (p.percentage === 0) {
    opt = zeroPercentageOpt;
  }
  return (
    <small
      className={p.className}
      style={{
        fontWeight: 600,
        ...p.style,
        color: opt.color,
      }}
    >
      {opt.icon === "" ? "" : <i className={opt.icon}></i>}
      {opt.val}
    </small>
  );
};

export default IncreasedPercentage;
