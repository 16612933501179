import { useState } from "react";
import { useSelector } from "react-redux";
import Card from "../Card";
import { State } from "../../State";
import OverlappingInfo from "../OverlappingInfo";
import style from "../analytics.module.css";
import LineChart from "../Charts/Lines/LineChart";
import Table from "../Tables/UtilityTables";
import PieChart from "../Charts/Pies/PieChart";
import { ChartRecord } from "../SideMenu/Base";
import SideMenuState from "./LeageState";
import IncreasedPercentage from "./IncreasedPercentage";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";

type RenderChartProp = {
  loading: boolean;
  expand: boolean;
  selectedIndex: number;
  records: ChartRecord[][];
};
const renderLineChart = (p: RenderChartProp) => {
  const { records, ...rest } = p;
  const mapLineChartData = (i: ChartRecord) => {
    const [year, month] = i.date.split("-");
    return {
      date: new Date(parseInt(year, 10), parseInt(month, 10) - 1, 1),
      value: i.consumption,
      siteName: i.siteName,
      fuelSourceName: i.fuelSourceName,
      //NOTE: not used, just to fullfill the interface
      cost: 0,
    };
  };
  return (
    <>
      <LineChart
        dataSet={
          p.records && p.records.length > 0 && p.records[0].length > 0
            ? p.records?.map((r) => r?.map(mapLineChartData))
            : []
        }
        {...rest}
      />
    </>
  );
};

const renderTable = (p: RenderChartProp) => {
  return (
    <>
      <Table {...p} records={p.records} />
    </>
  );
};

const renderPie = ({ monthConsumption = 0 }: { monthConsumption?: number }) => {
  return <PieChart value={monthConsumption} />;
};

type ConsumptionPanelProps = {
  records: ChartRecord[][];
  loading: boolean;
};

export default function ConsumptionPanel(p: ConsumptionPanelProps) {
  const [fullFirst, setFullFirst] = useState(false);

  const year = useSelector((state: State) => state.year);
  const display = useSelector((state: State) => state.display);
  const month = useSelector((state: State) => state.month);
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const utilityLeageState = SideMenuState();
  const { convertHtmlToImg } = useConvertHtmlToImg();

  const charData = {
    loading: p.loading,
    expand: fullFirst,
    selectedIndex: month.value,
    records: p.records,
  };

  const handleDownloadSvg = () => {
    switch (display.value) {
      case 0:
        return convertHtmlToImg("consumption");
      case 1:
        return convertHtmlToImg("utilityTable");
      case 2:
        return convertHtmlToImg("chatGraph");
      default:
        return convertHtmlToImg("consumption");
    }
  };

  return (
    <div className={`${style.quaterFirst} ${fullFirst && style.full} p-3`}>
      <Card
        onClick={() => setFullFirst(!fullFirst)}
        onDownloadClick={() => handleDownloadSvg()}
        isDisabledDownloadBtn={
          charData.records &&
          charData.records.length > 0 &&
          p.records[0].length > 0
            ? false
            : true
        }
        title="Consumption (kWh)"
        expanded={fullFirst}
        year={year.value}
        className={display.value === 1 ? "style-table" : ""}
        titleMenu={
          (fuelSource?.value === 5 ||
            fuelSource?.value === 2 ||
            fuelSource?.value === 4 ||
            fuelSource?.value === 6) && (
            <label className="checkbox-container">
              <input type="checkbox" />
              <span className="checkmark"></span>
              <span
                className="label"
                style={{
                  fontSize: "14px",
                }}
              >
                m<sup>3</sup> or litres
              </span>
            </label>
          )
        }
        top={
          <div className="d-flex align-items-center mb-2">
            <div
              className={`rounded-circle text-center p-2 d-flex flex-column 
                                    ${
                                      display.value === 2
                                        ? "me-auto"
                                        : "ms-auto"
                                    }`}
              style={{
                backgroundColor: "#E7E7E8",
              }}
            >
              <span className={`${style.sm17lg33} fw-bold`}>
                {utilityLeageState.selectedMonthDaysAmount}
              </span>
              <small className={style.sm7lg12}>days</small>
            </div>
            {display.value !== 2 && (
              <div className="mx-2 d-flex flex-column align-items-end">
                <span>
                  {utilityLeageState.currentRecord?.consumption -
                    utilityLeageState.previousRecord?.consumption || "N/A"}{" "}
                  kWh
                </span>
                <IncreasedPercentage
                  className={style.sm9lg17}
                  percentage={
                    utilityLeageState.currentRecord
                      ?.increasedConsumptionPercentage
                  }
                />
              </div>
            )}
            <OverlappingInfo
              text1="Average use per day"
              value1={`${
                utilityLeageState.currentRecord?.averageConsumption || "N/A"
              } kWh`}
              color1="#4989C6"
              text2="Consumption this month"
              value2={`${
                utilityLeageState.currentRecord?.consumption || "N/A"
              } kWh`}
              color2="#4D505C"
            />
          </div>
        }
        bottom={
          <div className="d-flex align-items-end mt-2">
            <span
              className={style.legendCircle}
              style={{
                backgroundColor: "#4989C6",
              }}
            />
            <span className={style.legend}>
              {fuelSource?.label} Consumption {year.value}
            </span>
            {display.value === 2 && (
              <div className="ms-auto d-flex flex-column align-items-end">
                <span
                  style={{
                    fontSize: "26px",
                  }}
                >
                  {utilityLeageState.currentRecord?.consumption || "N/A"} kHW
                </span>
                <IncreasedPercentage
                  style={{ fontSize: "15px" }}
                  percentage={
                    utilityLeageState.currentRecord?.increasedCostPercentage
                  }
                />
              </div>
            )}
          </div>
        }
      >
        {display.value === 0 && renderLineChart(charData)}
        {display.value === 1 && renderTable(charData)}
        {display.value === 2 &&
          renderPie({
            monthConsumption: utilityLeageState.currentRecord?.consumption,
          })}
      </Card>
    </div>
  );
}
