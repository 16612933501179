import Card from "../Card";
import OverlappingInfo from "../OverlappingInfo";
import style from "../analytics.module.css";
import BarChart from "../Charts/Bars/OrdinaryBarChart";
import { useSelector } from "react-redux";
import { State } from "../../State";
import SideMenuState from "./LeageState";
import IncreasedPercentage from "./IncreasedPercentage";
import { useCallback, useEffect, useState } from "react";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";
import { log } from "console";

type CostPanelProps = {
  isCallingFromEnergyWaste?: boolean;
  consumptions: any[];
  selectedYear: number;
  selectedMonthDaysAmount: number;
  currentRecord: any;
  increasedPercentage: number;
  currentMonthCost: number;
  averageMonthCost: number;
  diffCostPrevMonth: number;
  tooltipTag?: string;
};

export default function CostPanel(p: CostPanelProps) {
  // console.log("p.diffCostPrevMonth", p.diffCostPrevMonth);
  const [showVatCost, setShowVatCost] = useState(false);
  const [fullFirst, setFullFirst] = useState(false);
  const month = useSelector((state: State) => state.month);

  const utilityLeageState = SideMenuState();

  const fuelSource = useSelector((state: State) => state.fuelSource);
  const year = useSelector((state: State) => state.year);
  const site = useSelector((state: State) => state.site);
  const [costRecord, setCostRecord] = useState<any[]>([]);
  const { convertHtmlToImg } = useConvertHtmlToImg();

  useEffect(() => {
    const result = p.consumptions;

    setCostRecord(result);
  }, [p?.isCallingFromEnergyWaste, p.consumptions, fuelSource, year]);

  const onVatCheck = useCallback(() => {
    setShowVatCost((prev) => !prev);
  }, []);

  return (
    <div className={`${style.quaterThird} ${fullFirst && style.full} p-3`}>
      <Card
        onClick={() => setFullFirst(!fullFirst)}
        expanded={fullFirst}
        title={<>Cost (&#163;)</>}
        onDownloadClick={() => convertHtmlToImg("energyCost")}
        isDisabledDownloadBtn={costRecord.flat().length === 0}
        year={p.selectedYear}
        titleMenu={
          <label className="checkbox-container">
            <input
              checked={showVatCost}
              onChange={onVatCheck}
              type="checkbox"
            />
            <span className="checkmark"></span>
            <span
              className="label"
              style={{
                fontSize: "14px",
              }}
            >
              VAT inclusive
            </span>
          </label>
        }
        top={
          <div className="d-flex align-items-center">
            <div
              className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
              style={{
                backgroundColor: "#E7E7E8",
              }}
            >
              <span className={`${style.sm17lg33} fw-bold`}>
                {p.selectedMonthDaysAmount}
              </span>
              <small className={style.sm7lg12}>days</small>
            </div>
            <div className="mx-2 d-flex flex-column align-items-end">
              {p.diffCostPrevMonth && (
                <span>
                  {/* {p?.isCallingFromEnergyWaste === true
                  ? "£" + Math.abs(p.diffCostPrevMonth).toFixed(0)
                  : p.currentRecord?.cost
                  ? "£" + p.currentRecord.cost
                  : "N/A"} */}
                  {"£" + Math.abs(p.diffCostPrevMonth).toFixed(0) || "N/A"}
                </span>
              )}

              <IncreasedPercentage
                className={style.sm9lg17}
                percentage={p.increasedPercentage}
              />
            </div>
            <OverlappingInfo
              text1="Average cost per day"
              value1={p.averageMonthCost ? "£" + p.averageMonthCost : "N/A"}
              color1="#4989C6"
              text2="Cost this month"
              value2={p.currentMonthCost ? "£" + p.currentMonthCost : "N/A"}
              color2="#4D505C"
            />
          </div>
        }
        bottom={
          <div className="d-flex align-items-center mt-2">
            <span
              className={style.legendCircle}
              style={{
                backgroundColor: "#4989C6",
              }}
            />
            <span className={style.legend}>
              {fuelSource?.label} Cost {p.selectedYear}
            </span>
          </div>
        }
      >
        <BarChart
          expand={fullFirst}
          selectedIndex={month.value}
          records={costRecord}
          loading={utilityLeageState.isLoading}
          tooltipTag={p.tooltipTag}
          showVatCost={showVatCost}
        />
      </Card>
    </div>
  );
}
