import { useState } from "react";
import Card from "../Card";
import OverlappingInfo from "../OverlappingInfo";
import style from "../analytics.module.css";
import DoubleBarChart from "../Charts/Bars/StackedSigns";
import { useSelector } from "react-redux";
import { State } from "../../State";
import SideMenuState from "./LeageState";
import IncreasedPercentage from "./IncreasedPercentage";
import { useConvertHtmlToImg } from "../../Hooks/useConvertHtmlToImg";

type TargetConsumptionPanelProps = {};

const formatConsumptionData = (consumptionList: any) => {
  if (consumptionList.length === 0) {
    return [];
  }
  const consumptionRecords: any = [];
  for (let index = 0; index < consumptionList[0].length; index++) {
    if (consumptionList[1]) {
      const arr = [];
      if (consumptionList[0][index]) {
        arr.push(consumptionList[0][index]);
      }

      if (consumptionList[1][index]) {
        arr.push(consumptionList[1][index]);
      }
      consumptionRecords.push(arr);
    } else {
      consumptionRecords.push([consumptionList[0][index]]);
    }
  }
  return consumptionRecords;
};

export default function TargetConsumptionPanel(p: TargetConsumptionPanelProps) {
  const [fullSecond, setFullSecond] = useState(false);
  const month = useSelector((state: State) => state.month);
  const utilityLeageState = SideMenuState();
  const fuelSource = useSelector((state: State) => state.fuelSource);
  const { convertHtmlToImg } = useConvertHtmlToImg();

  const consumptionsRecords = formatConsumptionData(
    utilityLeageState.consumptions
  );

  return (
    <div className={`${style.quaterSecond} ${fullSecond && style.full} p-3`}>
      <Card
        onClick={() => setFullSecond(!fullSecond)}
        onDownloadClick={() => convertHtmlToImg("stackedSign")}
        title="Target/KPI (kWh)"
        expanded={fullSecond}
        isDisabledDownloadBtn={
          utilityLeageState?.energyTargets.flat().length === 0 ||
          consumptionsRecords.flat().length === 0
        }
        top={
          <div className="d-flex align-items-center">
            <div
              className="rounded-circle text-center p-2 d-flex flex-column ms-auto"
              style={{
                backgroundColor: "#E7E7E8",
              }}
            >
              <span className={`${style.sm17lg33} fw-bold`}>
                {utilityLeageState.selectedMonthDaysAmount}
              </span>
              <small className={style.sm7lg12}>days</small>
            </div>
            <IncreasedPercentage
              className={`${style.sm12lg17} mx-2`}
              percentage={
                Number(
                  utilityLeageState.calculatePercentageChange(
                    utilityLeageState.getCurrentEneryTarget()?.projectedEnergy,
                    utilityLeageState.getCurrentEneryTarget()?.consumption
                  )
                ) || 0
              }
            />
            <OverlappingInfo
              text1="Target Monthly usage"
              value1={`${
                utilityLeageState.getCurrentEneryTarget()?.projectedEnergy ||
                "N/A"
              } kWh`}
              color1="#94C11F"
              text2="Actual Monthly usage"
              value2={`${
                utilityLeageState.getCurrentEneryTarget()?.consumption || "N/A"
              } kWh`}
              color2="#4989C6"
            />
          </div>
        }
        bottom={
          <div className="d-flex align-items-center mt-2">
            <span
              className={style.legendCircle}
              style={{
                backgroundColor: "#4989C6",
              }}
            />
            <span className={`${style.legend} me-3`}>
              Actual {fuelSource?.label} Consumption{" "}
              {utilityLeageState.selectedYear.value}
            </span>
            <span
              className={style.legendCircle}
              style={{
                backgroundColor: "#94C11F",
              }}
            />
            <span className={style.legend}>
              Target {fuelSource?.label} Consumption{" "}
              {utilityLeageState.selectedYear.value}
            </span>
          </div>
        }
      >
        <DoubleBarChart
          expand={fullSecond}
          selectedIndex={month.value}
          energyTargetsRecords={utilityLeageState.energyTargets}
          loading={utilityLeageState.isLoading}
        />
      </Card>
    </div>
  );
}
